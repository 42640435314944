import Vue from 'vue'
import Popup from 'Patterns/vue/components/Popup.vue'
import { Button, Modal } from 'buefy'

Vue.use(Button)
Vue.use(Modal)

const popupSelector = '#vue-popup'
const rootElement = document.querySelector(popupSelector)
const componentProps = rootElement.attributes['data-vue'].value

export default class PopupComponent {
  constructor () {
    this.$trigger = rootElement
    if (this.$trigger) {
      this.app = new Vue({
        el: popupSelector,
        render: createElement => createElement(Popup, {
          props: JSON.parse(componentProps)
        })
      })
    }
  }
}

new PopupComponent()
