<template>
  <div class="App-buttons">
    <b-button
      v-if="iosDownloadImage !== false"
      :class="['is-paddingless u-mr10', { 'Disabled': !appStoreUrl }]"
      type="is-white"
      @click="handleButtonClick(appStoreUrl, 'button tap', 'Download iOS app')"
      v-html="iosDownloadImage"
    />
    <b-button
      v-if="androidDownloadImage !== false"
      :class="['is-paddingless', { 'Disabled': !playStoreUrl }]"
      type="is-white"
      @click="handleButtonClick(playStoreUrl, 'button tap', 'Download Android app')"
      v-html="androidDownloadImage"
    />
  </div>
</template>

<script>
import TrackingProvider from 'Shared/components/tracking/providers/segment-provider'

export default {
  name: 'AppButtons',

  props: {
    androidDownloadImage: {
      type: String,
      required: false
    },
    playStoreUrl: {
      type: String,
      required: false
    },
    iosDownloadImage: {
      type: String,
      required: false
    },
    appStoreUrl: {
      type: String,
      required: false
    },
    trackingScreen: {
      type: String,
      required: true
    }
  },

  methods: {
    handleButtonClick (target, name, eventStep) {
      if (target) {
        window.location = target
      }
      this.triggerAnalyticsEvent(name, eventStep)
    },

    triggerAnalyticsEvent (name, eventStep) {
      TrackingProvider.trackEvent(name, {
        step: eventStep,
        screen: this.trackingScreen,
        description: 'download_ios_app'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
b-button {
  cursor: pointer;
}
</style>
