var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPopup
    ? _c(
        "div",
        { staticClass: "Popup", style: { bottom: _vm.bottomOffset + "px" } },
        [
          _c("div", { staticClass: "Popup-modal", class: _vm.getBrand }, [
            _c("i", {
              staticClass: "Icon Icon-close Close-popup-modal",
              on: { click: _vm.closeModal },
            }),
            _vm._v(" "),
            _vm.popupImage && _vm.ctabuttontext && _vm.ctabuttonlink
              ? _c("div", { staticClass: "Popup-image-wrapper" }, [
                  _c("img", { attrs: { src: _vm.popupImage } }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "Popup-content",
                  {
                    "Full-width":
                      !_vm.popupImage ||
                      (_vm.popupImage &&
                        !_vm.ctabuttontext &&
                        !_vm.ctabuttonlink),
                  },
                ],
              },
              [
                _vm.heading
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "Popup-heading hb-font-text-bold u-mb5 u-mr10",
                      },
                      [_vm._v("\n        " + _vm._s(_vm.heading) + "\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.body
                  ? _c("p", {
                      staticClass: "Popup-body hb-font-text-regular u-mb10",
                      domProps: { innerHTML: _vm._s(_vm.body) },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.ctabuttontext && _vm.ctabuttonlink
                  ? _c(
                      "div",
                      { staticClass: "Popup-buttons u-mt15" },
                      [
                        _vm.lemonaid
                          ? _c(
                              "b-button",
                              {
                                attrs: { type: "is-subtle", expanded: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleButtonClick(
                                      _vm.ctabuttonlink,
                                      "button tap",
                                      "Clicked on Visit Lemonaid button.",
                                      "Lemonaid popup"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.ctabuttontext) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: { type: "is-subtle", expanded: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleButtonClick(
                                      _vm.ctabuttonlink,
                                      "button tap",
                                      "Clicked Popup CTA",
                                      "Popup"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.ctabuttontext) +
                                    "\n        "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "Popup-buttons u-mt15" },
                      [
                        _c("AppButtons", {
                          attrs: {
                            "android-download-image": _vm.androidDownloadImage,
                            "play-store-url": _vm.playStoreUrl,
                            "ios-download-image": _vm.iosDownloadImage,
                            "app-store-url": _vm.appStoreUrl,
                            "tracking-screen": "Popup",
                          },
                        }),
                      ],
                      1
                    ),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }