var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "App-buttons" },
    [
      _vm.iosDownloadImage !== false
        ? _c("b-button", {
            class: ["is-paddingless u-mr10", { Disabled: !_vm.appStoreUrl }],
            attrs: { type: "is-white" },
            domProps: { innerHTML: _vm._s(_vm.iosDownloadImage) },
            on: {
              click: function ($event) {
                return _vm.handleButtonClick(
                  _vm.appStoreUrl,
                  "button tap",
                  "Download iOS app"
                )
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.androidDownloadImage !== false
        ? _c("b-button", {
            class: ["is-paddingless", { Disabled: !_vm.playStoreUrl }],
            attrs: { type: "is-white" },
            domProps: { innerHTML: _vm._s(_vm.androidDownloadImage) },
            on: {
              click: function ($event) {
                return _vm.handleButtonClick(
                  _vm.playStoreUrl,
                  "button tap",
                  "Download Android app"
                )
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }